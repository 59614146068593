.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(18px );
  color: gray;
}

.App-Search-Bar {
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: gray;
    max-height: 5vh;
    max-width: 25vh;
}

.App-table {
     background-color: #ffffff;
     min-height: 40vh;
     align-items: center;
     justify-content: center;
     font-size: calc(5px + 1vmin);
     color: gray;
     margin: calc(10px + 3vw);
 }


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
